import UserActivity from './userActivity';

const NEWSLETTER_STORAGE_KEY = 'newsletterModalClosed';

function newsletterModal () {
    const isClosed = localStorage.getItem(NEWSLETTER_STORAGE_KEY);
    const modal = $('#newsletterModal');

    if (isClosed || !modal.length) return;

    const idleCallback = () => {
        modal.modal('show');
    };

    const userActivity = new UserActivity(idleCallback);
    userActivity.start();

    modal.on('hidden.bs.modal', function () {
        localStorage.setItem(NEWSLETTER_STORAGE_KEY, true);
        userActivity.stop();
    });

    $('#newsletterModal [name="emailId"]').on('focus', () => {
        $('#newsletterModal .subscribe-email, #newsletterModal .privacy-group').show();
    });

    $('body').on('newsletter:subscribeSuccess', () => {
        localStorage.setItem(NEWSLETTER_STORAGE_KEY, true);
        modal.modal('hide');
    });
}

export default newsletterModal;
