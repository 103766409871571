const TIMEOUT = 5000;

class UserActivity {

    callback;

    events = [
        'mousemove',
        'mousedown',
        'keydown',
        'touchstart',
        'touchmove',
        'scroll',
        'click',
        'pointermove',
        'pointerdown'
    ];

    timeoutId;

    constructor(callback) {
        this.callback = callback;
        this.resetListener = () => this.reset();
    }

    start() {
        this.reset();

        this.events.forEach(event => {
            document.addEventListener(event, this.resetListener, true);
        });
    }

    reset() {
        clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(this.callback, TIMEOUT);
    }

    stop() {
        clearTimeout(this.timeoutId);
        this.events.forEach(event => {
            document.removeEventListener(event, this.resetListener, true);
        });
    }
}

export default UserActivity;
