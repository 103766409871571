import Plyr from 'plyr';
import newsletterModal from './components/newsletterModal';

const initHomepageVideos = () => {
    const players = Array.from(document.querySelectorAll('.js-homepage-video-player'))
        .map((p) => {
            const ratio = $(p).data('video-ratio');
            return new Plyr(p, {
                controls: ['mute', 'play'],
                muted: true,
                resetOnEnd: true,
                ratio: ratio,
                loop: {active: true}
            });
        });

    if (!!players && players.length > 0) {
        const intersectionObserver = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                const key = $(entry.target).data('autoplayIndex');
                if (entry.intersectionRatio > 0) {
                    players[key].play();
                } else {
                    players[key].pause();
                }
            });
        });

        players.forEach((player, index) => {
            $(player.elements.container).data('autoplayIndex', index);
            player.on('ready', () => {
                player.muted = true;
                intersectionObserver.observe(player.elements.container);
            });
        });
    }
};

function init () {
    initHomepageVideos();
    newsletterModal();
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => {
        init();
    }, false);

} else {
    init();
}
